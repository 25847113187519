import React from "react";
import {
  MissionsContainer,
  MissionsPhoto,
  MissionsTitle,
  MissionsText,
  MissionsTextContainer,
  MissionsListContainer,
  MissionElementContainer,
  ProjectText,
  MissionIcon,
  MissionText,
  WhyText,
} from "./Missions.style";
import ImportStuff from "../../importStuff";
import pictureMissions from "../../assets/picture-missions.jpg";

function MissionsComponent(): JSX.Element {
  return (
    <MissionsContainer>
      <MissionsPhoto backgroundUrl={pictureMissions} />
      <MissionsText>
        <MissionsTextContainer>
          <WhyText>Pourquoi faire appel à Ocre Architecture ?</WhyText>
          <MissionsTitle>Missions</MissionsTitle>
          <ProjectText>
            Vous avez un projet de rénovation, extension, réhabilitation ou
            construction, d’un bâtiment à usage d’habitation ou professionnel,
            Ocre Architecture vous accompagne à travers les missions suivantes :
          </ProjectText>
          <MissionsListContainer>
            <MissionElementContainer>
              <MissionIcon backgroundUrl={ImportStuff.iconConseil} />
              <MissionText>Conseils et études de faisabilité</MissionText>
            </MissionElementContainer>
            <MissionElementContainer>
              <MissionIcon backgroundUrl={ImportStuff.iconEco} />
              <MissionText>
                Stratégie de rénovation performante
                <br />
                Etude bioclimatique
              </MissionText>
            </MissionElementContainer>
            <MissionElementContainer>
              <MissionIcon backgroundUrl={ImportStuff.iconConception} />
              <MissionText>
                Conception : implantation, composition et organisation des
                volumes, en plan et en 3D
              </MissionText>
            </MissionElementContainer>
            <MissionElementContainer>
              <MissionIcon backgroundUrl={ImportStuff.iconMaterials} />
              <MissionText>Choix des matériaux et des couleurs</MissionText>
            </MissionElementContainer>
            <MissionElementContainer>
              <MissionIcon backgroundUrl={ImportStuff.iconDevis} />
              <MissionText>Evaluation économique</MissionText>
            </MissionElementContainer>
            <MissionElementContainer>
              <MissionIcon backgroundUrl={ImportStuff.iconDemarche} />
              <MissionText>
                Démarches administratives
                <br />
                (permis de construire, déclaration préalable, etc…)
              </MissionText>
            </MissionElementContainer>
            <MissionElementContainer>
              <MissionIcon backgroundUrl={ImportStuff.iconAo} />
              <MissionText>Appels d’offre</MissionText>
            </MissionElementContainer>
            <MissionElementContainer>
              <MissionIcon backgroundUrl={ImportStuff.iconChantier} />
              <MissionText>
                Suivi de chantier et réception des travaux
              </MissionText>
            </MissionElementContainer>
          </MissionsListContainer>
        </MissionsTextContainer>
      </MissionsText>
    </MissionsContainer>
  );
}

export default MissionsComponent;
