import React from "react";
import {
  AboutContainer,
  AboutImage,
  AboutText,
  AboutTextContainer,
  AboutTitle,
  AboutMeText,
  AboutEnglishContainer,
  EnglishIcon,
  EnglishText,
} from "./About.style";
import ImportStuff from "../../importStuff";

function AboutComponent(): JSX.Element {
  return (
    <AboutContainer>
      <AboutText>
        <AboutTextContainer>
          <AboutTitle>A propos</AboutTitle>
          <AboutMeText>
            Fille de charpentier, Océane Garot a une double formation
            d’architecte et d’ingénieur en construction durable. Après dix
            riches années d’expériences en France et au Danemark, elle revient
            s’installer dans sa région natale en Bourgogne du Sud et crée Ocre
            Architecture.
          </AboutMeText>
          <AboutEnglishContainer>
            <EnglishIcon backgroundUrl={ImportStuff.iconEnglish} />
            <EnglishText>
              Ocre Architecture will be glad to develop your project in English.
            </EnglishText>
          </AboutEnglishContainer>
        </AboutTextContainer>
      </AboutText>
      <AboutImage backgroundUrl={ImportStuff.aboutDraw} />
    </AboutContainer>
  );
}

export default AboutComponent;
