import styled from "styled-components";

export const FooterContainer = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: rgb(255, 255, 255);
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const Footer = styled.div`
  padding: 0 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 768px) {
    padding: 0;
    align-items: center;
  }
`;

export const FooterText = styled.h2`
  font-family: Montserrat-Regular;
  font-size: 16px;
  font-weight: 100;
  line-height: 28px;
  color: rgb(13, 50, 69);
  margin: 0 0 40px 0;
  text-align: right;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const SocialNetworkContainer = styled.div`
  width: 150px;
  display: flex;
  justify-content: space-between;
`;
