import React from "react";
import {
  ApprocheContainer,
  ApprocheText,
  ApprocheTitle,
  ArchitectureContainer,
  ArchitectureText,
} from "./Architecture.style";
import ImportStuff from "../../importStuff";

function ArchitectureComponent(): JSX.Element {
  return (
    <ArchitectureContainer backgroundUrl={ImportStuff.motifOcre}>
      <ArchitectureText>
        <ApprocheContainer>
          <ApprocheTitle>Approche architecturale</ApprocheTitle>
          <ApprocheText>
            Un projet d’architecture se conçoit dans le dialogue et le partage
            avec le client, l’habitant, l’usager, les bureaux d’étude, les
            autorités administratives, les entreprises et les artisans : une
            intelligence collective au service du projet.
            <br />
            <br />
            Le dialogue se fait aussi avec le site, le territoire et
            l’environnement dans lequel il vient s’intégrer. Le paysage doit
            être donné à voir, la course du soleil doit orienter le projet, les
            matériaux locaux doivent s’imposer, le patrimoine et la culture
            locale doivent être respectés et réinterprétés. Pour garder une
            échelle humaine à l’acte de concevoir et celui de construire. Et
            donner du sens, sans oublier le bon sens.
            <br />
            <br />
            L’économie d’énergie, de technique et de matière est à rechercher,
            pour s’orienter vers une sobriété et une frugalité du bâti. Avec
            pour objectif, le confort et la fonctionnalité des espaces à vivre.
          </ApprocheText>
        </ApprocheContainer>
      </ArchitectureText>
    </ArchitectureContainer>
  );
}

export default ArchitectureComponent;
