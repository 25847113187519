import React from "react";
import AboutComponent from "./components/About/About";
import ArchitectureComponent from "./components/Architecture/Architecture";
import FooterComponent from "./components/Footer/Footer";
import HeaderComponent from "./components/Header/Header";
import IntroductionComponent from "./components/Introduction/Introduction";
import MissionsComponent from "./components/Missions/Missions";

function App(): JSX.Element {
  return (
    <React.StrictMode>
      <HeaderComponent />
      <IntroductionComponent />
      <MissionsComponent />
      <div style={{ backgroundColor: "rgb(13, 50, 69)" }}>
        <ArchitectureComponent />
        <AboutComponent />
      </div>
      <FooterComponent />
    </React.StrictMode>
  );
}

export default App;
