import React from "react";
import {
  Footer,
  FooterContainer,
  FooterText,
  SocialNetworkContainer,
} from "./Footer.style";
import { ReactComponent as InstagramIcon } from "../../assets/social-instagram.svg";
import { ReactComponent as FacebookIcon } from "../../assets/social-facebook.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/social-linkedin.svg";

function FooterComponent(): JSX.Element {
  return (
    <FooterContainer>
      <Footer>
        <FooterText>
          105, route du Monsard 71960 Bussières
          <br />
          contact@ocre-architecture.fr
          <br />
          07 49 22 65 56
        </FooterText>
        <SocialNetworkContainer>
          <a href="https://www.instagram.com/ocre_architecture/" target="_blank" rel="noreferrer">
            <InstagramIcon />
          </a>
          <a href="https://www.facebook.com/ocrearchitecture" target="_blank" rel="noreferrer">
            <FacebookIcon />
          </a>
          <a href="https://www.linkedin.com/in/oceane-garot/" target="_blank" rel="noreferrer">
            <LinkedinIcon />
          </a>
        </SocialNetworkContainer>
      </Footer>
    </FooterContainer>
  );
}

export default FooterComponent;
