import styled from "styled-components";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 370px;
  background-color: rgb(13, 50, 69);
`;

export const Header = styled.div`
  max-width: 1300px;
  height: 370px;
  margin: 0 auto;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: space-around;
  }
`;

export const EmptyContainer = styled.div`
  width: 90px;
`;

export const Logo = styled.img`
  height: 150px;
`;

export const SocialNetworkContainer = styled.div`
  width: 90px;
  display: flex;
  justify-content: space-between;
`;
