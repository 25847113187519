import styled from "styled-components";
import { ImageProps } from "../../dto";

export const IntroductionContainer = styled.div`
  width: 100%
  height: 580px;
  background-color: rgb(13,50,69);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 768px) {
    height: 720px;
    flex-direction: column;
  }
`;

export const IntroductionText = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    padding: 50px 0;
    align-items: center;
  }
`;

export const IntroductionTextContainer = styled.div`
  padding: 0 50px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const AccompanyText = styled.h2`
  font-family: Montserrat-Medium;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin: 0;
  color: rgb(205, 156, 77);
`;

export const EcoText = styled.h1`
  font-family: Arapey-Regular;
  font-size: 75px;
  font-weight: 100;
  margin: 45px 0 45px 0;
  color: rgb(205, 156, 77);
  @media (max-width: 768px) {
    font-size: 70px;
  }
`;

export const ProjectText = styled.h2`
  font-family: Montserrat-Medium;
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 0.08em;
  margin: 0;
  color: rgb(205, 156, 77);
`;

export const IntroductionPhoto = styled.div<ImageProps>`
  width: 40%;
  height: 580px;
  background: url(${(props) => props.backgroundUrl}) no-repeat center;
  background-size: cover;
  @media (max-width: 768px) {
    width: 100%;
    padding: 50px 0;
  }
`;
