import aboutDraw from "./assets/about-draw.jpg";
import iconEnglish from "./assets/icone-anglais-ocre.png";
import motifOcre from "./assets/motif-ocre.png";
import logoOcreBlanc from "./assets/logo-ocre-blanc.png";
import iconConseil from "./assets/icone-conseil-bleu.png";
import iconEco from "./assets/icone-eco-construction-bleu.png";
import iconConception from "./assets/icone-conception-bleu.png";
import iconMaterials from "./assets/icone-materiaux-bleu.png";
import iconDevis from "./assets/icone-devis-bleu.png";
import iconDemarche from "./assets/icone-demarche-bleu.png";
import iconAo from "./assets/icone-appel-d-offre-bleu.png";
import iconChantier from "./assets/icone-chantier-bleu.png";

const yo = {
  aboutDraw,
  iconEnglish,
  motifOcre,
  logoOcreBlanc,
  iconConseil,
  iconEco,
  iconConception,
  iconMaterials,
  iconDevis,
  iconDemarche,
  iconAo,
  iconChantier
};

export default yo
