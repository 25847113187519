import styled from "styled-components";
import { ImageProps } from "../../dto";

export const MissionsContainer = styled.div`
  display: flex;
`;

export const MissionsPhoto = styled.div<ImageProps>`
  width: 30%;
  background: url(${(props) => props.backgroundUrl}) no-repeat center;
  background-size: cover;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MissionsText = styled.div`
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const MissionsTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 75px 125px;
  @media (max-width: 768px) {
    padding: 30px;
  }
`;

export const WhyText = styled.h2`
  font-family: Montserrat-Medium;
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin: 0;
  color: rgb(13, 50, 69);
  text-align: center;
`;

export const MissionsTitle = styled.h1`
  font-family: Arapey-Regular;
  font-size: 75px;
  font-weight: 100;
  letter-spacing: 0.015em;
  margin: 40px 0;
  color: rgb(205, 156, 77);
`;

export const ProjectText = styled.h2`
  font-family: Montserrat-Regular;
  font-size: 18px;
  font-weight: 100;
  color: rgb(13, 50, 69);
  line-height: 28px;
  max-width: 800px;
  margin: 0;
  text-align: center;
`;

export const MissionsListContainer = styled.div`
  width: 100%;
`;

export const MissionElementContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 90px;
`;

export const MissionIcon = styled.div<ImageProps>`
  min-width: 140px;
  height: 140px;
  margin-right: 75px;
  background: url(${props => props.backgroundUrl}) no-repeat center;
  @media (max-width: 768px) {
    margin-right: 10px;
  }
`;

export const MissionText = styled.h1`
  font-family: Arapey-Regular;
  font-size: 28px;
  font-weight: 100;
  line-height: 38px;
  margin: 0;
  color: rgb(13, 50, 69);
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
`;
