import React from "react";
import {
  EmptyContainer,
  Header,
  HeaderContainer,
  Logo,
  SocialNetworkContainer,
} from "./Header.style";
import ImportStuff from "../../importStuff";
import { ReactComponent as InstagramIcon } from "../../assets/social-instagram.svg";
import { ReactComponent as FacebookIcon } from "../../assets/social-facebook.svg";

function HeaderComponent(): JSX.Element {
  return (
    <HeaderContainer>
      <Header>
        <EmptyContainer />
        <Logo alt="Logo Ocre Architecture" src={ImportStuff.logoOcreBlanc} />
        <SocialNetworkContainer>
          <a href="https://www.instagram.com/ocre_architecture/" target="_blank" rel="noreferrer">
            <InstagramIcon />
          </a>
          <a href="https://www.facebook.com/ocrearchitecture" target="_blank" rel="noreferrer">
            <FacebookIcon />
          </a>
        </SocialNetworkContainer>
      </Header>
    </HeaderContainer>
  );
}

export default HeaderComponent;
