import styled from "styled-components";
import { ImageProps } from "../../dto";

export const ArchitectureContainer = styled.div<ImageProps>`
  width: 100%;
  height: 1150px;
  background: url(${(props) => props.backgroundUrl}) no-repeat top;
  background-size: auto 1150px;
`;

export const ArchitectureText = styled.div`
  width: 75%;
  min-height: 460px;
  display: flex;
  justify-content: center;
  padding: 160px 0;
  background-color: rgb(205, 156, 77);
  @media (max-width: 768px) {
    width: 100%;
    padding: 50px 0;
  }
`;

export const ApprocheContainer = styled.div`
  padding: 0 50px;
  @media (max-width: 768px) {
    padding: 0 30px;
  }
`;

export const ApprocheTitle = styled.h1`
  font-family: Arapey-Regular;
  font-size: 55px;
  font-weight: 100;
  margin: 0 0 50px 0;
  color: rgb(255, 255, 255);
  @media (max-width: 768px) {
    font-size: 54px;
  }
`;

export const ApprocheText = styled.h1`
  font-family: Montserrat-Regular;
  font-size: 18px;
  font-weight: 100;
  color: rgb(255, 255, 255);
  line-height: 28px;
  margin: 0;
  max-width: 850px;
`;
