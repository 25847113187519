import styled from "styled-components";
import { ImageProps } from "../../dto";

export const AboutContainer = styled.div`
  max-width: 1400px;
  margin: -95px auto 0 auto;
  padding: 0 0 110px 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin: -10px auto 0 auto;
    flex-direction: column-reverse;
  }
`;

export const AboutText = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AboutTextContainer = styled.div`
  padding: 50px;
`;

export const AboutTitle = styled.h1`
  font-family: Arapey-Regular;
  font-size: 75px;
  font-weight: 100;
  margin: 0 0 50px 0;
  color: rgb(205, 156, 77);
`;

export const AboutMeText = styled.h2`
  font-family: Montserrat-Regular;
  font-size: 16px;
  font-weight: 100;
  color: rgb(255, 255, 255);
  line-height: 28px;
  margin: 0 0 50px 0;
`;

export const AboutEnglishContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const EnglishIcon = styled.div<ImageProps>`
  min-width: 125px;
  height: 90px;
  margin: 0 50px 0 0;
  background: url(${props => props.backgroundUrl}) no-repeat center;
  @media (max-width: 768px) {
    margin: 0 0 30px 0;
  }
`;

export const EnglishText = styled.h2`
  font-family: Montserrat-Regular;
  font-size: 16px;
  font-weight: 100;
  line-height: 28px;
  color: rgb(205, 156, 77);
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const AboutImage = styled.div<ImageProps>`
  width: 48%;
  height: 1050px;
  background: url(${(props) => props.backgroundUrl}) no-repeat center;
  background-size: cover;
  @media (max-width: 768px) {
    width: 100%;
    height: 580px;
  }
`;
