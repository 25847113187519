import React from "react";
import {
  AccompanyText,
  EcoText,
  IntroductionContainer,
  IntroductionPhoto,
  IntroductionText,
  IntroductionTextContainer,
  ProjectText,
} from "./Introduction.style";
import pictureIntroduction from "../../assets/picture-introduction.jpg";

function IntroductionComponent(): JSX.Element {
  return (
    <IntroductionContainer>
      <IntroductionText>
        <IntroductionTextContainer>
          <AccompanyText>Ocre Architecture vous accompagne :</AccompanyText>
          <EcoText>
            Eco conception,
            <br /> rigueur & sensibilité
          </EcoText>
          <ProjectText>
            Pour des projets ancrés dans leur environnement.
          </ProjectText>
        </IntroductionTextContainer>
      </IntroductionText>
      <IntroductionPhoto backgroundUrl={pictureIntroduction} />
    </IntroductionContainer>
  );
}

export default IntroductionComponent;
